<template>
	<div class="page_box" v-loading="loading">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">姓名</div>
					<div class="inputs_box">
					 
						<el-input clearable type="text" placeholder="请输入" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box" v-if='!$route.query.id'>
						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
						@confirm="confirm_bumen_cascader_value" />
					</div>
					<div class="inputs_box" v-if='$route.query.id'>
					 
					 <el-input clearable type="text" placeholder="请输入" v-model="bm_title" disabled />
				 </div>
				</div>
				<div class="search_flex">
					<div class="texts">时间</div>
					<div class="inputs_box">

						<el-date-picker v-model="forms.month" clearable value-format="yyyy-MM-dd" type="month" :disabled="$route.query.time?true:false"
							placeholder="选择月" :editable="false" :picker-options="pickerOptions"></el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>
		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">{{ page_title }}</div>
				<div class="rights">
					<!-- <div class="btns" @click="do_shenhe()">审核</div>
					<div class="btns" @click="do_xiafa()">下发</div>
					<div class="btns" @click="do_report()">上报</div> -->
					<div class="btns" @click="do_import()" v-if='$route.query.id&&$route.query.state!=40'>当月其它费用导入</div>
					<!-- <div class="btns" @click="do_edit()">编辑</div> -->
					<div class="btns" @click="do_export()">导出</div>
				</div>
			</div>

			<div class="filter-box">
				<div class="filter-group" v-for="(group, gindex) in column_group">
					<div class="filter-label">{{ group.parent_title }}</div>
					<div class="filter-items">
						<!-- <el-checkbox-group v-model="group.checkList"> -->
						<el-checkbox v-for="(item, index) in  group.child" :label="item.title" :disabled="item.disabled"
							v-model="item.ac_show">{{ item.title }}</el-checkbox>
						<!-- </el-checkbox-group> -->
					</div>
				</div>
				<div class="filter-group" v-for="(group, gindex) in column_group2">
					<div class="filter-label">{{ group.parent_title }}</div>
					<div class="filter-items">
						<!-- <el-checkbox-group v-model="group.checkList"> -->
						<el-checkbox v-for="(item, index) in  group.child" :label="item.title" :disabled="item.disabled"
							v-model="item.ac_show" @change="change_type">{{ item.title }}</el-checkbox>
						<!-- </el-checkbox-group> -->
					</div>
				</div>
			</div>



			<div class="bom_box_list" v-if="tab_show">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- height="570" -->

					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55" label="序号">
          </el-table-column>-->
					<!-- fixed -->
					<!-- <el-table-column fixed type="selection" width="55">
					</el-table-column> -->
					<el-table-column prop="date" fixed label="日期" width="120">
						<template slot-scope="scope">
							<div slot="reference" class="" style="color: #2373C8;">
								{{ scope.row.gz_date }}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="id" label="月份" width="120">

					</el-table-column> -->
					<!-- <el-table-column prop="id" label="姓名" width="120"></el-table-column> -->

					<!-- <el-table-column prop="name" label="部门" width="120"></el-table-column> -->
					<!-- <el-table-column prop="mobile" label="岗位" width="120"></el-table-column> -->
					<!-- <el-table-column prop="mobile" label="出勤" width="120"></el-table-column> -->
					<el-table-column :prop="item.id" :fixed='index==0' :label="item.title" width="120"
						v-for="(item, index) in column_group[0].child" v-if="item.ac_show"></el-table-column>

					<el-table-column prop="mobile" :label="item.parent_title" width="120"
						v-for="(item, index) in column_group2" :key="index" v-if="(item.child.find(v => v.ac_show))">
						<!-- :prop="'fee_'+item.id+'.money'" -->
						<el-table-column :prop="`fee_${items.id}.money`" :label="items.title"
							v-for="(items, indexs) in item.child" width="120" v-if="items.ac_show"></el-table-column>

					</el-table-column>



					<el-table-column prop="payable_gz" label="应发工资" width></el-table-column>
					<el-table-column prop="gz_gz" label="实发工资" width></el-table-column>

					<!-- <el-table-column prop="payable_gz" label="实发工资" width></el-table-column>
					<el-table-column prop="reward_gz" label="工资" width></el-table-column>
					<el-table-column prop="gz_gz" label="奖金" width></el-table-column> -->
					<el-table-column prop="mobile" label="实发工资" width="120">
						<!-- :prop="'fee_'+item.id+'.money'" -->
						<el-table-column prop="reward_gz" label="奖金"></el-table-column>
						<el-table-column prop="actual_gz" label="工资"></el-table-column>

					</el-table-column>
					<!-- 操作模块   -->
					<el-table-column fixed="right" label="操作" width="140"  v-if='$route.query.id&&$route.query.state!=40'>
						<template slot-scope="scope">
							<!-- 	<el-button type="text" size="small" class="btn_open" @click="handle_btn_open(scope.row)"
								:disabled="scope.row.status=='open'">编辑</el-button>
							<el-button type="text" size="small" class="btn_close" @click="handle_btn_colse(scope.row)"
              :disabled="scope.row.status=='close'">{{ $t('btn_close') }}</el-button>-->
							<el-button type="text" size="small" class="btn_modify"
								@click="do_edit_salary(scope.row)">编辑</el-button>
							<!-- <el-button type="text" size="small" class="btn_delete"
								@click="handle_btn_delete(scope.row)">减员</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson="count" @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
		<!-- 导入 -->
		<up_files ref="up_files" @handle_up_end="handle_up_end"></up_files>

		<!--  -->
		<shebao_user_add_modal data-title="社保增员" ref="shebao_user_add_modal" />
		<shebao_user_delete_modal data-title="社保减员" ref="shebao_user_delete_modal" />
	</div>
</template>
<script>
import page from "@/components/page/page.vue"; //分页  统一的
import shebao_user_add_modal from "@/components/shebao/shebao_user_add_modal.vue"; //增员
import shebao_user_delete_modal from "@/components/shebao/shebao_user_delete_modal.vue"; //减员

export default {
	components: {
		page,
		shebao_user_add_modal,
		shebao_user_delete_modal,
	},
	data() {
		return {
			loading: false, //加载总
			tab_show: false,
			page_title: "", //页面名称  来之路由
			check_list: [], //选中的
			//提交数据
			forms: {
				keyword: "",
				// depart_id: "",
				month: '',
			},
			bm_title:'',
			bm_list: [],//部门
			bm_list_ac: [],//部门选中
			props: {
				multiple: false,
				value: 'id',
				label: 'title'
			},
			list: [
			],
			pages: {
				page: 1,
				limit: 10,
			},
			count: 0,
			times: [], //时间范围

			//字段
			column_group: [{
				parent_title: "基本信息",

				child: [
					{
						id: "user.name",
						title: "姓名",
						disabled: true,
						ac_show: true,
					},
					{
						id: "user.depart_title",
						title: "部门",
						disabled: true,
						ac_show: true,

					},
					{
						id: "user.level6",
						title: "岗位",
						disabled: true,
						ac_show: true,

					},
					{
						id: "atten_day",
						title: "出勤",
						disabled: true,
						ac_show: true,
					},
				],
			},
			],
			column_group2: [],
			pickerOptions: {
				disabledDate(time) {
					console.log("time", time);
					return time.getMonth() > new Date().getMonth();
				},
			},
		};
	},

	methods: {
		//接受条数
		currentPage(page) {
			console.log(page);
			this.pages.page = page;
			this.get_list();
		},
		// 接收条数
		row(row) {
			console.log(row);
			this.pages.limit = row;
			this.get_list();
		},
		//选择
		handle_list_change(e) {
			console.log("多选", e);
			this.check_list = e;
		},
		//搜素
		handle_search() {
			this.pages.page = 1;
			this.get_list();
		},
		//表单回调 触发重新渲染数据
		handle_get_list() {
			this.get_list();
		},
		//统一的列表接口
		get_list() {
			console.log(this.forms);
			this.loading = true;
			let depart_id=''
			if(this.bm_list_ac.length!=0){
				depart_id=this.bm_list_ac[this.bm_list_ac.length-1]
			}
			if(this.$route.query.id){
				depart_id=this.$route.query.id

			}
			let send_id=''
			if(this.$route.query.send_id){
				send_id=this.$route.query.send_id
			}
			this.$api("empSalaryList", {
				...this.forms,
				...this.pages,
				depart_id:depart_id,
				send_id:send_id,
			}, "post").then((res) => {
				this.loading = false;
				this.tab_show = true

				if (res.code == 200) {
					this.count = res.data.count;
					this.list = res.data.list;
				} else {
					alertErr(res.msg);
				}
			});
		},
		confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
			this.$log('勾选部门数据', value)
			this.bm_list_ac=value
		},
		//获取部门
		get_bm_list() {

			// return

			this.loading = true
			this.$api("getCategoryAllList", {

			}, "get").then((res) => {
				console.log('所有的部门', res)
				this.loading = false
				if (res.code == 200) {
					let level = 0//最高等级
					let list = []
					let data = []
					res.data.forEach((e) => {
						if (e.is_gw != 1) {
							data.push(e)
						}
					})
					data.forEach((e, i) => {
						if (e.level > level) {
							level = e.level
						}
						if (e.is_gw != 1) {
							this.$set(e, 'children', [])
						}
					})
					console.log('最高登记', level)


					for (let i = 1; i <= level; i++) {
						let level_list = []
						data.forEach((e) => {
							if (e.level == i) {
								level_list.push(e)
							}


						})
						list.push(level_list)
					}
					console.log('列表1', list)
					// 总列表  最后一位的下标
					this.bm_list_data(list, list.length - 1)
				} else {
					alertErr(res.msg)
				}
			});
		},
		//处理部门列表
		bm_list_data(lists, indexs) {
			let index1 = indexs //当前下标
			let index2 = indexs - 1 //上级下标
			let list = lists
			list[index1].forEach((e) => {
				list[index2].forEach((v) => {
					if (v.id == e.parent_id) {
						v.children.push(e)
					}
				})
			})
			list[index2].forEach((e) => {
				if (e.children.length == 0) {
					this.$delete(e, 'children')
				}
			})
			if (index2 == 0) {
				console.log('最后的数据', list)
				this.bm_list = list[0]
				return
			}
			this.bm_list_data(list, index2)
		},


		//编辑
		handle_btn_modify(row) {
			this.$refs.shebao_user_add_modal.init(row);
		},
		//减员
		handle_btn_delete(row) {
			this.$refs.shebao_user_delete_modal.init(row);
		},

		//岗位薪资设置
		do_shenhe() {
			// this.$router.push("/position_setting");
		},
		//下发
		do_xiafa() { },
		//上报
		do_report() { },
		//导入
		do_import() { 
			let obj={
				file_url:'http://jinniu.dx.hdapp.com.cn/dr_file/当月费用导入模板.xlsx',
			}
			this.$refs.up_files.handle_open(obj)
		},
		//上传回调
		handle_up_end(info) {
			console.log(info)
			
			this.$api("socialImportXls", {
				file: info.path,
				type:'fee',
			}, "post").then((res) => {
				alert(res)
				if (res.code == 200) {
					this.get_list()
				}
			});
		},
		//编辑
		do_edit() { },
		//导出
		do_export() {
			if(this.bm_list_ac.length==0){
				alertErr('请先选择部门')
				return
			}
			if (!this.forms.month) {
				alertErr('请先选择月份')
				return
			}
			let depart_id=this.bm_list_ac[this.bm_list_ac.length-1]
			let month=this.forms.month
			this.loading = true;
			let column_group=[]
			this.column_group2.forEach((e)=>{
				e.child.forEach((v)=>{
					if(!v.ac_show){
						column_group.push(v.id)		
					}
				})
			})
			this.$api("exportSalaryMonth", {
				depart_id:depart_id,
				month:month,
				check:1,
				column_group:column_group.join('|'),
			}, "get").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					// window.open(res)
					window.open(`http://jinniu.dx.hdapp.com.cn/api/exportSalaryMonth?token=${localStorage.getItem('token')}&depart_id=${depart_id}&month=${month}&column_group=${column_group.join('|')}`)
				} else {
					alertErr(res.msg);
				}
			});
		},
		//去编辑员工薪资
		do_edit_salary(row) {
			this.$router.push(`/Employee_salary_modifications?id=${row.id}&send_id=${this.$route.query.send_id}`);
		},
		get_type() {
			this.loading = true;
			let send_id=''
			if(this.$route.query.send_id){
				send_id=this.$route.query.send_id
			}
			this.$api("feeProjectList", {
				// mobile:this.mobile
				...this.forms,
				...this.pages,
				send_id:send_id
			}, "post").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					console.log('配置列表', res)
					let obj = {};
					let list = res.data
					list.forEach((item, index) => {
						item.ac_show = item.selected
						// this.$set(item,'ac_show',true)
						let { parent_title } = item;
						if (!obj[parent_title]) {
							obj[parent_title] = {
								parent_title,
								child: [],//全部的
							}
						}
						obj[parent_title].child.push(item);
					});
					let data = Object.values(obj);
					console.log('结果', data)
					this.column_group2 = data


				} else {
					alertErr(res.msg);
				}
			});
		},
		change_type() {
			this.tab_show = false
			this.get_list()

		},
	},
	computed: {},
	watch: {},
	created() {
		this.page_title = this.$route.meta.title;
		if (this.$route.query.id) {
			console.log('传过来的数据', this.$route.query)
			this.forms.depart_id = this.$route.query.id
			this.forms.month = this.$route.query.time
			this.bm_title = this.$route.query.title

		}else{
			console.log('时间',new Date().getFullYear())
			console.log('时间',new Date().getMonth()+1)
			let time=new Date().getFullYear()+'-'+((new Date().getMonth()+1)>10?'':'0')+(new Date().getMonth()+1)
			this.forms.month = time
		}
		// this.get_bm_list()
		this.get_list()
		this.get_type()

	},
	mounted() { },
};
</script>

<style scoped lang="less">
.filter-box {
	padding: 30px 20px;
	padding-bottom: 0;

	.filter-group {
		margin-bottom: 10px;
		display: flex;
		align-items: flex-start;

		.filter-label {
			min-width: 100px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			font-size: 14px;
			color: #333333;
		}

		.filter-items {
			flex: 1;
			overflow: hidden;

			.el-checkbox {
				margin-bottom: 20px;
			}
		}
	}
}

.page_box {
	height: 100%;

	// background-color: red;
	.top_box {
		width: 100%;
		// height: 80px;
		background: #ffffff;
		border-radius: 8px 8px 8px 8px;
		padding-bottom: 29px;

		.top_title {
			padding: 10px 17px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #333333;
		}

		.top_flex {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			flex-wrap: wrap;
			padding: 0 77px;

			.search_flex {
				// width: 25%;
				display: flex;
				align-items: center;
				padding-right: 48px;

				.texts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
					white-space: nowrap;
					padding-right: 12px;
				}

				.inputs_box {
					flex: 1;

					input {
						width: 236px;
						height: 40px;
						background: #ffffff;
						border-radius: 0px 0px 0px 0px;
						border: 1px solid #e8e8e8;
						padding: 0 9px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #747474;
					}
				}

				.btn_ends {
					width: 80px;
					height: 40px;
					background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
					border-radius: 2px 2px 2px 2px;
					text-align: center;
					line-height: 40px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #ffffff;
					cursor: pointer;

					&:hover {
						background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
					}
				}
			}
		}
	}

	.bom_box {
		background: #ffffff;

		.bom_btn_flex {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 51px;
			background: #ffffff;
			box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
			border-radius: 4px 4px 4px 4px;
			margin-top: 20px;
			padding: 0 20px;

			.lefts {
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.rights {
				display: flex;
				align-items: center;

				.btns {
					margin-left: 20px;
					background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
					padding: 6px 13px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #ffffff;
					cursor: pointer;

					&:hover {
						background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
					}
				}
			}
		}

		.bom_box_list {
			width: 1640px;
			// height: 670px;
			padding: 20px 20px 32px 20px;

			.pages {
				padding-top: 30px;
			}
		}
	}
}
</style>